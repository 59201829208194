'use client';

import { ComponentProps } from 'react';
import { useSession } from 'next-auth/react';
import { SearchSelect } from '../search-select';
import { useRouter } from 'next/navigation';

const defaultProps = {
  noOptionsMessage: ({ inputValue }) =>
    inputValue ? 'No Results' : 'Type to Search',
  components: {
    DropdownIndicator: () => '🔎'
  }
} as ComponentProps<typeof HeaderSearch>;

export function HeaderSearch(
  props: Partial<ComponentProps<typeof SearchSelect>>
) {
  const { data: session } = useSession();
  const router = useRouter();

  return (
    !!session && (
      <div className="header-search">
        <SearchSelect
          {...defaultProps}
          {...props}
          placeholder="Search"
          onChange={val => {
            if (val?.entityType === 'USER') {
              router.push(`/player/${val.entityId}`);
            } else if (val?.entityType === 'TEAM') {
              router.push(`/teams/${val.entityId}`);
            }
          }}
        />
      </div>
    )
  );
}
