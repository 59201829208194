import { ComponentProps } from 'react';
import { Image as ImageBS } from 'react-bootstrap';
export function Image(props: ComponentProps<typeof ImageBS>) {
  return (
    <ImageBS
      {...props}
      onError={e =>
        ((e.target as HTMLImageElement).src =
          '/public/images/logo-grayscale.png')
      }
    />
  );
}
