'use client';

import { hasPermission } from '@cc/utils/permissions';
import { useSession } from 'next-auth/react';
import Link from 'next/link';
import { usePathname, useSearchParams } from 'next/navigation';
import { Image, NavDropdown, NavLink } from 'react-bootstrap';

export function HeaderLogin() {
  const { data: session } = useSession();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  return session ? (
    <NavDropdown
      data-flip
      title={
        <Image
          height="50"
          alt="discord avatar image"
          src={session.user?.image || '/public/images/logo.png'}
          roundedCircle
        />
      }
      id="header-dropdown"
    >
      <NavDropdown.Item as={Link} href="/profile">
        Profile
      </NavDropdown.Item>
      <NavDropdown.Item href="/api/auth/signout">Logout</NavDropdown.Item>
      {hasPermission(session.user?.role) && (
        <>
          <NavDropdown.Item href="/admin">Admin</NavDropdown.Item>
          <NavDropdown.Item
            href={`/api/admin/revalidate?callbackUrl=${encodeURIComponent(
              `${pathname}?${searchParams}`
            )}`}
          >
            Clear Cache
          </NavDropdown.Item>
        </>
      )}
    </NavDropdown>
  ) : (
    <NavLink href="/api/auth/signin">Login</NavLink>
  );
}
