import Image from 'next/image';
import { Container, Nav, Navbar, NavbarBrand } from 'react-bootstrap';
import { HeaderLogin } from './header/login';
import Link from 'next/link';
import { HeaderSearch } from './header/search';

export function PageHeader(props: { renderUserActions?: boolean }) {
  const { renderUserActions } = props;
  return (
    <Navbar className="page-header">
      <Container>
        <Nav className="align-items-center">
          <NavbarBrand as={Link} href="/">
            <Image
              src="/public/images/logo.png"
              height={50}
              width={50}
              alt="Logo with nested Cs"
            />{' '}
            <span className="d-none d-md-inline">Console Contenders</span>
          </NavbarBrand>
        </Nav>
        {renderUserActions && (
          <Nav className="user-actions">
            <HeaderSearch />
            <HeaderLogin />
          </Nav>
        )}
      </Container>
    </Navbar>
  );
}
