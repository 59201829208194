'use client'; // Error components must be Client Components

import { useEffect } from 'react';
import { Alert, AlertHeading, Button, Container } from 'react-bootstrap';
import { PageHeader } from '@/app/components/page-header';

export default function Error({
  error,
  reset
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);
  }, [error]);

  return (
    <>
      <PageHeader />
      <Container>
        <Alert variant="warning">
          <AlertHeading>Something went wrong!</AlertHeading>
          <p>Error code: {error.digest}</p>
          <p>
            <Button
              variant="primary"
              onClick={
                // Attempt to recover by trying to re-render the segment
                () => reset()
              }
            >
              Try again
            </Button>
          </p>
        </Alert>
      </Container>
    </>
  );
}
