'use client';

import { ComponentProps } from 'react';
import Async from 'react-select/async';

import styles from './search-select.module.scss';
import { Image } from '@/components/image';

type OptionType = {
  entityId: number;
  entityType: 'USER' | 'TEAM';
  displayName: string;
  subline?: string | null;
  avatarUrl: string;
};

const defaultProps = {
  styles: {
    control: (styles, props) => {
      const { isValid, isInvalid } =
        props.selectProps as typeof props.selectProps & {
          isValid?: boolean;
          isInvalid?: boolean;
        };
      return {
        ...styles,
        backgroundColor: props.isDisabled
          ? 'var(--bs-secondary-bg)'
          : 'var(--bs-body-bg)',
        color: 'var(--bs-body-color)',
        // border: 'var(--bs-border-width) solid var(--bs-border-color)',
        borderWidth: 'var(--bs-border-width)',
        ...(isValid
          ? {
              borderColor: 'var(--bs-form-valid-border-color)'
            }
          : isInvalid
            ? {
                borderColor: 'var(--bs-form-invalid-border-color)'
              }
            : {
                borderColor: 'var(--bs-border-color)'
              })
      };
    },
    input: styles => ({
      ...styles,
      color: 'var(--bs-body-color)'
    }),
    singleValue: styles => ({
      ...styles,
      color: 'var(--bs-body-color)'
    }),
    placeholder: styles => ({
      ...styles,
      color: 'var(--bs-body-color)'
    }),
    menu: styles => ({
      ...styles,
      backgroundColor: 'var(--bs-body-bg)'
    }),
    option: (styles, props) => {
      return {
        ...styles,
        ...(props.isFocused
          ? {
              backgroundColor: 'var(--bs-dropdown-link-hover-bg)',
              color: 'var(--bs-dropdown-link-hover-color)'
            }
          : {})
      };
    }
  },
  className: styles.searchSelect,
  classNamePrefix: 'react-select',
  getOptionLabel: value => value.displayName,
  getOptionValue: value => value.entityId.toString(),
  formatOptionLabel: data => {
    return (
      <div className={styles.searchSelectLabel}>
        <Image src={data.avatarUrl} />
        <div>
          {data.displayName}
          {data.subline && <small>{data.subline}</small>}
        </div>
      </div>
    );
  },
  cacheOptions: true
} as Partial<ComponentProps<typeof Async<OptionType>>>;

export function SearchSelect({
  ...rest
}: ComponentProps<typeof Async<OptionType>> & {
  isValid?: boolean;
  isInvalid?: boolean;
}) {
  return (
    <Async
      {...defaultProps}
      isClearable
      loadOptions={async (search = '') => {
        const searchParams = new URLSearchParams({
          search
        });
        return fetch('/api/search?' + searchParams.toString()).then(res =>
          res.json()
        );
      }}
      {...rest}
    />
  );
}
